import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';

interface DroppableContainer {
  children: any;
  id: string;
  items: string[];
  minW: number;
  isCorrect?: boolean;
  allBlanksEmpty?: boolean;
  style?: object;
}

export const DroppableContainer: FC<DroppableContainer> = ({
  children,
  id,
  items,
  isCorrect,
  allBlanksEmpty,
  style,
  minW,
}) => {
  const { over, isOver, setNodeRef } = useDroppable({
    id,
    data: {
      accepts: 'WORD_BANK',
    },
  });
  const isOverContainer = isOver || (over ? items.includes(over.id) : false);

  return (
    <Box position="relative">
      <Box
        ref={setNodeRef}
        position="absolute"
        top="-1px"
        bottom="-1px"
        left="-8px"
        right="-8px"
      />
      <Box
        display='inline-block'
        minW={`${minW}px`}
        minH='40px'
        p='2px'
        my='1'
        borderWidth='2px'
        rounded='md'
        transition='background-color .35s ease'
        sx={style}
        css={(themes: any) => {
          if (isOverContainer) {
            return {
              backgroundColor: themes.theme.colors.gray[400],
            };
          }

          if (!allBlanksEmpty && typeof isCorrect === 'boolean') {
            return {
              backgroundColor: themes.theme.colors[isCorrect ? 'green' : 'red'][100],
            };
          }

          // if (!allBlanksEmpty) {
          //   return {
          //     backgroundColor: themes.theme.colors[isCorrect ? 'green' : 'red'][100],
          //   };
          // }
        }}>
        {children.length ? (
          children
        ) : (
          <Flex align='center' w='full' h='full'>
            &nbsp;
          </Flex>
        )}
      </Box>
    </Box>
  );
};
