import { Box, Flex, Transform } from '@chakra-ui/react';
import { CSS } from '@dnd-kit/utilities';
import { FiCheck, FiX } from 'react-icons/fi';
import { keyframes } from '@emotion/react';
import React, { ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { Cancel } from '@mui/icons-material';

const boxShadowBorder = '0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05)';
const boxShadowCommon = '0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)';
const boxShadow = `${boxShadowBorder}, ${boxShadowCommon}`;

type ItemI = {
  taskId: string;
  dragging: boolean;
  dragOverlay: boolean;
  listeners: object;
  style?: object;
  transition: string;
  transform: Transform;
  value: string;
  isCorrect?: boolean;
};

const pop = keyframes({
  from: {
    transform: 'scale(1)',
    boxShadow: 'var(--box-shadow)',
  },
  to: {
    transform: 'scale(var(--scale))',
    boxShadow: 'var(--box-shadow-picked-up)',
  },
});

const wrapperDragOverlay = (dragOverlay: boolean) =>
  dragOverlay
    ? {
        '--scale': 1.05,
        '--box-shadow': boxShadow,
        '--box-shadow-picked-up': {
          '--box-shadow-picked-up': [
            boxShadowBorder,
            '-1px 0 15px 0 rgba(34, 33, 81, 0.01)',
            '0px 15px 15px 0 rgba(34, 33, 81, 0.25)',
          ].toString(),
        },
        zIndex: 999,
      }
    : {};

function getItemStyles(val: { dragging: boolean; dragOverlay: boolean }) {
  const { dragging, dragOverlay } = val;
  if (dragOverlay) {
    return {
      cursor: 'inherit',
      animation: `${pop} 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)`,
      transform: 'scale(1.3)',
      boxShadow: 'var(--box-shadow-picked-up)',
      opacity: 1,
    };
  }

  if (dragging) {
    return {
      opacity: 0.2,
      zIndex: 0,

      '&:focus': {
        boxShadow,
      },
    };
  }
}

export const Item = memo(
  forwardRef((props: any, ref: ForwardedRef<any>) => {
    const [isDisplayingAlertIcon] = useState<boolean>(typeof props.isCorrect === 'boolean');
    const [prop1, setProp1] = useState<any>(props);
    useEffect(() => {
      setProp1({ ...props });
      if (props?.dragging) {
        delete props?.dragging;
      }
      // if (props?.isCorrect) {
      //   delete props?.isCorrect;
      // }
    }, []);
    return (
      <Flex
        handle
        justifySelf='center'
        w={`${props.minV}px`}
        transformOrigin='top left'
        style={{
          transform: CSS.Transform.toString(props.transform),
          transition: props.transition,
        }}
        sx={{
          touchAction: 'manipulation',
          ...wrapperDragOverlay(props.dragOverlay),
          height: 8,
          width: 'auto',
        }}
        ref={ref}>
        <Flex
          pos='relative'
          grow='1'
          align='center'
          justify='space-between'
          color='black'
          px='3'
          boxShadow={isDisplayingAlertIcon ? 'none' : boxShadow}
          outline='none'
          whiteSpace='nowrap'
          width='aouto'
          rounded='sm'
          cursor='grab'
          sx={{
            WebkitTapHighlightColor: 'transparent', // for mobile
            fontSize: '16px',

            // only show focus outline when using keyboard
            '&:focus-visible': {
              boxShadow: 'outline',
              touchAction: 'none',
              userSelect: 'none',
              WebkitUserSelect: 'none',
            },

            ...getItemStyles({ dragging: props.dragging, dragOverlay: props.dragOverlay }),
            ...props.style,
          }}
          css={(themes: any) => ({
            backgroundColor: !isDisplayingAlertIcon && themes.theme.colors.blackAlpha[100],
          })}
          {...props.listeners}
          {...props}
          tabIndex={0}>
          {props.value}
          {props.value?.toString() === '' && <div style={{ color: 'transparent' }}>ddd</div>}
          {typeof props.isCorrect === 'boolean' ? (
            <Box
              as={props.isCorrect ? FiCheck : FiX}
              ml='1'
              color={`${!props.hasSubmitted ? 'blue' : props.isCorrect ? 'green.600' : 'red'}`}
            />
          ) : props.isMap && !props.hasSubmitted ? (
            <Cancel sx={{ cursor: 'pointer' }} onClick={props.isMapRemove} />
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    );
  }),
);
